.logs-card {
  background-color: white;
  border-radius: 1rem;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 0.1);
  width: 100%;
  height: fit-content;
  padding: 1.5rem 2rem;
}
.logs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.logs-heading {
  color: #262626;
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0;
}
.logs-sub-heading {
  color: #737373;
  font-size: 15px;
  font-weight: 500;
}
.logs-divider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #a3a3a3;
  margin: 1rem 0;
}
.logs-div {
  max-height: 192px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 1rem;
}
.logs-div::-webkit-scrollbar {
  width: 8px;
}
.logs-div::-webkit-scrollbar-track {
  background: transparent;
}
.logs-div::-webkit-scrollbar-thumb {
  background-color: #a3a3a340;
  border-radius: 24px;
}
.log-row {
  height: 3rem;
  width: 100%;
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}
.log-row:last-child {
  margin-bottom: 0;
}
.log-platform {
  height: 100%;
  width: 0.75rem;
}
.log-title {
  width: 200px;
  color: #262626;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding-left: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.log-divider {
  height: 100%;
  width: 4px;
  border-right: 4px solid #a3a3a340;
}
.log-description-div {
  width: 75%;
  padding: 0 1rem;
}
.log-description {
  width: 100%;
  color: #737373;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.logs-create {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.no-logs-div {
  height: 3rem;
  width: 100%;
  background-color: #dc354540;
  border: 1px solid #dc354580;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.no-logs-p {
  font-size: 1rem;
  font-weight: 500;
  color: #dc3545;
  margin: 0;
}
