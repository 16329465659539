/* tooltip */

.tp {
  position: relative;
  display: inline-block;
  background-color: aquamarine;
  width: auto;
}

.tp .tp-text {
  visibility: hidden;
  width: 120px;
  background-color: rgb(109, 109, 109);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: 100%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tp:hover .tp-text {
  visibility: visible;
}
