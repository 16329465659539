.back-button {
  background-color: var(--main-color);
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  font-size: 20px;
  z-index: 10;
}

.back-button:hover {
  transform: scale(1.1) !important;
  transition: transform 0.3s ease 0s !important;
  /* background-color: inherit !important; */
}
